import Cookies from 'js-cookie'

export enum CookieKeys {
  GAUID = '_ga',
  PHPSESSID = 'PHPSESSID',
  TEST_VARIANT = 'test_variant',
  TEST_NAME = 'test_name',
  CUSTOMER_TOKEN = 'customerToken',
}

export function getCookie(key: string) {
  return Cookies.get(key)
}

export function setCookie(
  key: string,
  value: string,
  options?: Cookies.CookieAttributes,
): string | undefined {
  return Cookies.set(key, value, options)
}

export function removeCookie(key: string) {
  Cookies.remove(key)
}

export const parseCookie = (str: string): Record<string, string> =>
  str
    .split(';')
    .map((v) => v.split(/=(.+)/))
    .reduce((acc, [key, value]) => {
      const isKeyEmpty = key == null || key.trim().length === 0
      const isValueEmpty = value == null || value.trim().length === 0

      if (!isKeyEmpty && !isValueEmpty) {
        return {
          ...acc,
          [decodeURIComponent(key.trim())]: decodeURIComponent(value.trim()),
        }
      }

      return acc
    }, {})
