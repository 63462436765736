import { StoreCodeType } from '../types'
import { Currency } from '../types/price-types'

export type PriceFormatSettings = {
  price: number
  storeCode: StoreCodeType
  currency?: Currency | null
  currencyFormat?: string
  dualCurrency?: boolean
}

export function formatPrice({
  currency,
  price,
  storeCode,
  currencyFormat,
  dualCurrency = false,
}: PriceFormatSettings) {
  const { thousandDelimiter, fractionDelimiter, fractionDigits } =
    getFormatOptionsByStoreCode(storeCode, dualCurrency)

  const [roundedPrice, fractionPrice = ''] = price
    .toFixed(fractionDigits)
    .split('.')

  const formattedPrice = [
    (+roundedPrice).toLocaleString('en-US').replace(/,/g, thousandDelimiter),
    ...(fractionPrice ? [fractionPrice] : []),
  ].join(fractionDelimiter)

  if (currencyFormat) {
    return currencyFormat.replace('%s', formattedPrice)
  }

  const formattedCurrency = getFormattedCurrency(storeCode, currency)

  return getCurrencyFormatPrice(
    storeCode,
    formattedPrice,
    dualCurrency,
    formattedCurrency,
  )
}

function getFormatOptionsByStoreCode(
  storeCode: StoreCodeType,
  dualCurrency: boolean,
): {
  thousandDelimiter: string
  fractionDelimiter: string
  fractionDigits: number
} {
  if (dualCurrency) {
    return {
      thousandDelimiter: ',',
      fractionDelimiter: '.',
      fractionDigits: 2,
    }
  }

  switch (storeCode) {
    case 'gymbeamcom':
      return {
        thousandDelimiter: ',',
        fractionDelimiter: '.',
        fractionDigits: 2,
      }
    case 'gymbeampl':
    case 'gymbeambg':
    case 'ua':
    case 'ru':
      return {
        thousandDelimiter: '',
        fractionDelimiter: ',',
        fractionDigits: 2,
      }
    case 'gymbeamhu':
      return {
        thousandDelimiter: '\xa0',
        fractionDelimiter: ',',
        fractionDigits: 0,
      }
    case 'gymbeamro':
    case 'gymbeamsi':
    case 'gymbeamba':
    case 'gymbeamrs':
    case 'gymbeamgr':
    case 'gymbeamit':
    case 'gymbeamhr':
    case 'gymbeamde':
      return {
        thousandDelimiter: '.',
        fractionDelimiter: ',',
        fractionDigits: 2,
      }
    case 'gymbeamsk':
    case 'gymbeamcz':
    default:
      return {
        thousandDelimiter: '\xa0',
        fractionDelimiter: ',',
        fractionDigits: 2,
      }
  }
}

function getFormattedCurrency(
  storeCode: StoreCodeType,
  currency?: Currency | null,
): string | undefined | null {
  switch (currency) {
    case 'EUR': {
      switch (storeCode) {
        case 'gymbeamhr':
          return 'EUR'
        default:
          return '€'
      }
    }
    case 'CZK':
      return 'Kč'
    case 'PLN':
      return 'zł'
    case 'HUF':
      return 'Ft'
    case 'RON':
      return 'Lei'
    case 'BAM':
      return 'KM'
    case 'RSD':
      return 'RSD'
    case 'BGN':
      return 'лв.'
    case 'UAH':
      return 'грн'
    default:
      return currency
  }
}

function getCurrencyFormatPrice(
  storeCode: StoreCodeType,
  price: string,
  dualCurrency: boolean,
  formattedCurrency?: string | null,
): string {
  if (dualCurrency) {
    return `${price} ${formattedCurrency}`
  }

  switch (storeCode) {
    case 'gymbeamcom':
      return `${formattedCurrency}${price}`
    case 'gymbeampl':
    case 'gymbeamhu':
    case 'gymbeamro':
    case 'gymbeamba':
    case 'gymbeambg':
      return `${price}${formattedCurrency}`
    case 'gymbeamrs':
    case 'gymbeamsk':
    case 'gymbeamcz':
    case 'gymbeamsi':
    case 'gymbeamgr':
    case 'gymbeamit':
    case 'gymbeamhr':
    case 'gymbeamde':
    case 'ua':
    case 'ru':
    default:
      return `${price} ${formattedCurrency}`
  }
}

export function roundPrice(price: number): string {
  return price.toFixed(2)
}
